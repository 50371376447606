import React from 'react'


const success = (props) => (

    <div className="Success">
        <img src="https://www.uhb-rh.hr//assets/correct.png" />
        <h3>Povratak na stranicu?</h3>
        <a href="https://www.uhb-rh.hr">UHB RH</a>
    </div>
);

export default success;